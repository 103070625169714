// eslint-disable-next-line no-console
import React, { useEffect, useRef, useState } from "react";
import { useNotify, Notification } from "react-admin";
import QRCode from "qrcode";
import { useNavigate } from "react-router";
import classes from "../CustomLogin/CustomLogin.module.scss";
import http from "../../../utils/http";

function SetupTwoFactor() {
  const [verificationCode, setVerificationCode] = useState("");
  const notify = useNotify();
  const [base32, setBase32] = useState("");
  const [otp, setOTP] = useState("");
  const navigate = useNavigate();

  // to send code and base 32 on connect
  // to send on login after success setup
  // to generate qr from  otpauth
  useEffect(() => {
    const fetchData = async () => {
      const res = await http.get("/api/auth/otp");
      setBase32(res?.data.base32);
      setOTP(res?.data.otpAuth_url);
    };

    fetchData();
  }, []);

  const canvasRef = useRef();

  useEffect(() => {
    QRCode.toCanvas(
      canvasRef.current,
      // QR code doesn't work with an empty string
      // so we are using a blank space as a fallback
      otp || " ",
      // eslint-disable-next-line no-console
      (error) => error && console.error(error)
    );
  }, [otp]);

  const handleSubmit = (e) => {
    setup(e);
  };

  function setup(e) {
    e.preventDefault();
    http
      .post("/api/auth/otp", { verificationCode, otpSecret: base32 })
      .then(() => {
        navigate("/admin/login");
      })
      .catch(() => {
        notify("Invalid code. Please try again.");
      });
  }

  return (
    <div className={classes.setupWrapper}>
      <h1 className={classes.title}>Connect an Authenticator app</h1>
      <form className={classes.wrapper} onSubmit={handleSubmit}>
        <p className={classes.description}>
          Connect an Authenticator App Connect an authenticator app that
          generates verification codes. You can use the codes when we need to
          verify your identity. Download and install an authenticator app on
          your mobile device. Use the app to scan this QR code. Enter the code
          generated by the app.
        </p>
        <canvas ref={canvasRef} />
        <div className={classes.inputWrapper}>
          <input
            name="verificationCode"
            value={verificationCode}
            className={classes.input}
            onChange={(e) => setVerificationCode(e.target.value)}
          />
        </div>
        <button className={classes.signIn} type="submit">
          Connect
        </button>
      </form>
    </div>
  );
}

function CustomSetupPage() {
  return (
    <>
      <Notification />
      <SetupTwoFactor />
    </>
  );
}

export default CustomSetupPage;
