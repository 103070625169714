import React from "react";

export default function ApiErrorImage(props) {
  return (
    <div {...props}>
      <svg
        width="279"
        height="233"
        viewBox="0 0 279 233"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          opacity="0.4"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M243.61 232.353C265.621 207.691 279 175.158 279 139.5C279 62.4563 216.544 0 139.5 0C62.4563 0 0 62.4563 0 139.5C0 175.158 13.3785 207.691 35.3899 232.353H243.61Z"
          fill="#CCEAFF"
        />
        <g clipPath="url(#clip2_4337_9428)">
          <path
            d="M249.937 232.451H29.063C27.3613 232.451 26 231.054 26 229.349C26 227.645 27.3613 226.247 29.063 226.247H249.937C251.639 226.247 253 227.645 253 229.349C253 231.054 251.639 232.451 249.937 232.451Z"
            fill="#C4C4C4"
          />
          <path
            d="M102.713 225.437C95.9974 225.437 90.5312 219.961 90.5312 213.235V50.7334C90.5312 44.0069 95.9974 38.5317 102.713 38.5317H176.897C183.613 38.5317 189.079 44.0069 189.079 50.7334V213.266C189.079 219.993 183.613 225.468 176.897 225.468H102.713V225.437Z"
            fill="white"
          />
          <path
            d="M176.866 39.0637C183.3 39.0637 188.516 44.2886 188.516 50.7336V213.266C188.516 219.711 183.3 224.936 176.866 224.936H102.713C96.2783 224.936 91.062 219.711 91.062 213.266V50.7336C91.062 44.2886 96.2783 39.0637 102.713 39.0637H176.866ZM176.866 38H102.713C95.7161 38 90 43.6941 90 50.7336V213.266C90 220.275 95.6848 226 102.713 226H176.897C183.894 226 189.61 220.306 189.61 213.266V50.7336C189.578 43.6941 183.894 38 176.866 38Z"
            fill="#C3DEF4"
          />
          <path
            d="M185.237 51.3282V200.815C185.237 205.226 181.645 208.824 177.241 208.824H102.338C97.9339 208.824 94.3418 205.226 94.3418 200.815V51.3282C94.3418 46.9168 97.9339 43.3188 102.338 43.3188H177.241C181.676 43.3188 185.237 46.9168 185.237 51.3282Z"
            fill="url(#paint0_linear_4337_9428)"
          />
          <path
            d="M147.536 219.305H132.043C131.2 219.305 130.481 218.617 130.481 217.741C130.481 216.896 131.169 216.176 132.043 216.176H147.536C148.379 216.176 149.098 216.865 149.098 217.741C149.066 218.617 148.379 219.305 147.536 219.305Z"
            fill="#C7DFF2"
          />
        </g>
        <g clipPath="url(#clip3_4337_9428)">
          <path
            d="M115.853 108.504L114.393 111.982L119.126 113.976L120.586 110.498C122.148 110.818 123.77 110.825 125.367 110.514L126.793 114.008L131.537 112.063L130.112 108.57C131.47 107.656 132.63 106.499 133.525 105.201L137.003 106.661L138.997 101.928L135.519 100.468C135.839 98.9061 135.846 97.2843 135.537 95.6657L139.03 94.2403L137.085 89.4959L133.59 90.9633C132.676 89.6056 131.52 88.4449 130.222 87.5502L131.682 84.072L126.948 82.0778L125.487 85.577C123.925 85.2572 122.303 85.2502 120.707 85.5394L119.261 82.045L114.516 83.9898L115.941 87.4831C114.585 88.3758 113.445 89.5335 112.549 90.8524L109.05 89.3913L107.078 94.1047L110.556 95.5647C110.237 97.1267 110.23 98.7485 110.539 100.367L107.045 101.793L108.99 106.537L112.483 105.112C113.376 106.468 114.534 107.608 115.853 108.504ZM119.127 100.872C117.564 98.6982 118.028 95.6907 120.202 94.1277C122.355 92.5635 125.382 93.0501 126.946 95.2029C128.51 97.3556 128.024 100.383 125.871 101.947C123.697 103.51 120.691 103.025 119.127 100.872Z"
            fill="#0B70B7"
          />
          <path
            d="M115.151 103.745C112.005 99.3964 112.979 93.3206 117.307 90.1725C121.655 87.0256 127.731 87.9999 130.879 92.3276C134.026 96.6763 133.052 102.752 128.724 105.9C124.396 109.048 118.3 108.073 115.151 103.745ZM116.36 102.865C119.018 106.533 124.196 107.373 127.865 104.693C131.533 102.035 132.373 96.857 129.693 93.1878C127.034 89.5197 121.877 88.7024 118.188 91.3598C114.518 94.0394 113.701 99.1971 116.36 102.865Z"
            fill="#879DD1"
          />
        </g>
        <g clipPath="url(#clip4_4337_9428)">
          <path
            d="M147.967 85.9498L146.521 90.0235L152.065 92L153.511 87.9264C155.319 88.1915 157.175 88.0951 158.983 87.6371L160.839 91.542L166.142 89.0111L164.286 85.1062C165.78 83.9733 167.034 82.5752 167.974 81.0326L172.048 82.4788L174.024 76.9349L169.95 75.4886C170.216 73.6808 170.119 71.8248 169.661 69.9928L173.566 68.1368L171.035 62.8339L167.13 64.7381C165.997 63.2436 164.599 61.9902 163.057 61.0502L164.503 56.9765L158.959 55L157.513 59.0977C155.705 58.8326 153.849 58.929 152.041 59.3629L150.161 55.458L144.858 57.9889L146.714 61.8938C145.22 63.0026 143.99 64.4007 143.05 65.9674L138.952 64.5212L137 70.041L141.074 71.4873C140.808 73.2951 140.905 75.1511 141.363 76.9831L137.458 78.8391L139.989 84.142L143.894 82.286C145.003 83.7805 146.401 85.0098 147.967 85.9498ZM151.221 77.0072C149.293 74.6208 149.631 71.1498 152.017 69.2215C154.379 67.2932 157.874 67.6547 159.803 70.0169C161.731 72.3792 161.369 75.8743 159.007 77.8026C156.621 79.7309 153.15 79.3694 151.221 77.0072Z"
            fill="#0B70B7"
          />
          <path
            d="M146.859 80.5506C142.978 75.7779 143.701 68.7636 148.45 64.8828C153.222 61.002 160.237 61.7252 164.118 66.4737C167.998 71.2463 167.275 78.2607 162.527 82.1415C157.778 86.0222 150.74 85.2991 146.859 80.5506ZM148.185 79.4659C151.463 83.4913 157.441 84.118 161.466 80.8157C165.491 77.5376 166.118 71.5597 162.816 67.5343C159.538 63.5089 153.584 62.9063 149.534 66.1845C145.509 69.4867 144.906 75.4405 148.185 79.4659Z"
            fill="#879DD1"
          />
        </g>
        <path
          d="M208.973 128.244C208.743 128.382 208.513 128.496 208.26 128.565H208.237C206.697 128.977 204.305 129.825 203.523 129.436C203.339 129.344 203.316 129.138 203.431 128.977C203.661 128.702 204.535 126.503 204.742 125.472C204.765 125.312 204.65 125.151 204.489 125.128C202.926 124.899 201.569 124.464 200.971 123.731C200.948 123.685 200.925 123.639 200.902 123.594C200.672 122.7 200.603 121.738 200.649 120.753C200.649 120.638 201.086 117.522 201.109 117.499C201.339 117.133 202.006 113.124 202.167 112.666C202.213 112.551 202.282 112.482 202.397 112.482C203.156 112.391 206.237 112.024 206.444 112.07C206.674 112.139 212.261 113.994 212.399 114.155C212.537 114.315 213.457 118.737 211.985 122.196C211.41 123.525 210.629 124.327 209.847 124.808C209.847 124.808 208.536 127.076 208.973 128.244Z"
          fill="#EAC49F"
        />
        <path
          d="M210.698 174.361C211.112 174.155 212.813 173.216 213.894 170.169C213.94 170.031 213.894 169.894 213.779 169.825C212.124 168.794 200.673 161.646 197.936 162.517C197.086 162.792 195.499 189.344 195.522 189.894C195.522 190.169 195.499 193.056 195.522 193.376C195.706 197.889 197.292 220.41 197.476 222.907C197.476 223.045 197.591 223.159 197.729 223.182C198.166 223.251 199.247 223.388 200.834 223.388C200.949 223.297 201.017 223.228 201.132 223.136C201.845 217.088 204.972 205.725 203.409 198.6C203.386 198.508 203.386 198.416 203.363 198.348C203.363 198.21 203.34 198.027 203.317 197.775C202.926 194.613 202.926 195.553 203.8 191.566C203.8 191.543 203.8 191.543 203.823 191.521C205.754 186.549 209.41 177.5 210.606 174.499C210.583 174.43 210.629 174.384 210.698 174.361Z"
          fill="#004942"
        />
        <path
          d="M226.701 219.906C226.747 220.021 226.701 220.181 226.609 220.25C224.885 221.487 223.666 221.624 223.666 221.624C223.666 221.624 223.666 221.624 223.666 221.601C223.252 220.96 210.629 201.074 208.191 197.202C208.145 197.111 208.099 197.042 208.053 196.973C208.053 196.95 208.03 196.95 208.03 196.927C207.869 196.652 207.731 196.4 207.639 196.148C207.616 196.103 207.593 196.057 207.57 196.011C207.501 195.851 207.455 195.713 207.432 195.599C207.409 195.507 207.409 195.438 207.386 195.347C207.386 195.347 207.386 195.324 207.386 195.301C207.042 193.583 206.237 190.65 205.271 187.214C204.719 185.266 204.098 183.181 203.5 181.074C203.018 179.424 202.535 177.775 202.098 176.194L198.557 162.517C198.511 162.333 198.649 162.173 198.833 162.15L201.661 161.967C205.248 161.715 209.64 159.928 210.583 159.516C210.698 159.47 210.813 159.493 210.905 159.584C215.02 163.685 214.239 168.84 213.733 171.406C213.733 171.406 213.733 171.406 213.733 171.429C213.503 172.62 213.434 173.811 213.526 175.026C213.802 178.737 214.354 185.656 214.423 190.467C214.423 190.467 214.423 190.467 214.423 190.49C214.423 190.627 214.63 193.491 217.366 196.882C217.895 197.454 218.378 198.05 218.837 198.714C222.907 204.923 224.425 214.499 226.701 219.906Z"
          fill="#035B52"
        />
        <path
          d="M215.458 143.479C215.435 144.991 215.343 146.503 215.228 147.947C215.067 149.734 214.86 151.429 214.63 152.964C214.193 156.057 213.871 160.41 213.779 161.715C213.756 161.944 213.618 162.151 213.388 162.242C213.25 162.288 213.135 162.334 212.997 162.403C206.582 164.739 197.132 162.746 197.132 162.746C196.741 162.769 196.35 162.471 196.511 161.738C196.626 161.188 197.224 158.462 197.201 157.889C197.178 157.362 197.339 155.553 197.637 153.055C197.752 152.162 197.867 151.2 198.005 150.169C198.12 149.298 198.258 148.382 198.419 147.42C198.603 146.32 198.741 145.243 198.879 144.189C199.04 142.998 199.201 141.83 199.362 140.684C199.661 138.714 200.029 136.812 200.558 134.98C200.995 133.468 201.546 131.978 202.305 130.535C204.099 127.099 208.422 126.572 209.18 126.503C209.249 126.503 209.318 126.503 209.387 126.549C212.607 128.702 214.262 132.391 214.998 136.583C215.343 138.783 215.481 141.142 215.458 143.479Z"
          fill="#FDB73E"
        />
        <path
          d="M201.224 222.335L201.891 224.466C202.121 225.222 201.546 226.001 200.742 226.001H190.808C190.808 226.001 190.578 224.168 192.878 223.687C195.2 223.228 197.269 222.312 197.269 222.312H201.224V222.335Z"
          fill="#13220F"
        />
        <path
          d="M226.471 219.127L227.782 220.937C228.242 221.579 227.943 222.518 227.184 222.77L217.757 225.886C217.757 225.886 216.952 224.213 218.998 223.045C221.045 221.877 222.723 220.365 222.723 220.365L226.471 219.127Z"
          fill="#13220F"
        />
        <path
          d="M210.214 124.12C210.214 124.12 215.779 120.386 215.756 115.094C215.71 109.801 205.5 106 203.086 106.136C200.672 106.272 197.89 108.702 202.281 113.215C204.742 115.735 208.881 115.895 208.881 115.895L208.168 119.172C208.168 119.172 209.64 117.018 210.605 117.224C212.445 117.614 212.077 122.035 210.214 124.12Z"
          fill="#13220F"
        />
        <path
          d="M192.645 160.694L194.494 158.506C195.932 156.824 195.71 154.306 194.022 152.873L150.563 116.098C150.718 96.6003 134.021 99.7973 134.021 99.7973L140.77 105.506L138.911 113.33L131.914 115.939L125.277 110.024C125.871 131.51 143.533 124.39 143.533 124.39L186.991 161.164C188.679 162.597 191.23 162.375 192.645 160.694ZM186.61 158.967C184.737 157.375 184.376 154.72 185.791 153.039C187.229 151.357 189.919 151.272 191.792 152.864C193.664 154.456 194.025 157.111 192.61 158.793C191.172 160.474 188.482 160.559 186.61 158.967Z"
          fill="#074674"
        />
        <path
          d="M208.963 134.176C210.164 131.866 207.611 130.723 207.012 130.711C206.343 130.679 204.516 130.814 200.528 133.915C197.785 136.054 195.505 136.247 194.286 137.335C193.905 137.66 193.436 138.036 193.055 138.36C192.102 139.048 190.584 140.048 189.483 140.5C187.308 141.367 179.595 144.718 175.53 146.146C175.206 146.261 174.182 146.599 173.64 146.542C172.996 146.473 171.977 146.511 171.359 146.404C171.327 146.41 171.276 146.404 171.244 146.41C171.244 146.41 169.81 146.201 168.901 146.698L169.629 147.181C168.975 147.627 168.404 148.155 168.449 148.378C168.559 148.836 170.063 150.52 171.491 150.697C172.868 150.868 172.94 149.927 173.468 149.672C174.797 149.041 176.642 148.505 176.642 148.505C178.958 147.925 181.276 147.675 184.853 146.941C188.57 146.162 192.191 145.155 193.977 144.25C194.187 144.142 196.673 143.014 196.844 142.798C200.308 140.779 204.91 138.498 207.238 136.111C208.177 135.194 208.792 134.475 208.963 134.176Z"
          fill="#EAC49F"
        />
        <path
          d="M193.837 136.764C194.003 138.386 196.68 141.838 198.054 142.886C200.239 141.339 212.524 134.257 210.396 131.141C208.025 127.727 197.57 133.548 193.837 136.764Z"
          fill="#FDB73E"
        />
        <g clipPath="url(#clip5_4337_9428)">
          <path
            d="M106.11 173.27V156H73.8896V173.27C73.8896 179.653 77.6066 185.157 82.9935 187.753C84.2053 188.339 84.9996 189.551 84.9996 190.904V191.116C84.9996 192.469 84.2053 193.681 82.9935 194.267C77.6066 196.853 73.8896 202.357 73.8896 208.74V226.01H106.11V208.74C106.11 202.357 102.393 196.853 97.0057 194.257C95.7939 193.671 94.9996 192.459 94.9996 191.106V190.894C94.9996 189.541 95.7939 188.329 97.0057 187.743C102.393 185.157 106.11 179.643 106.11 173.27Z"
            fill="#1C907D"
          />
          <path
            d="M78.0859 179.239C80.2753 183.572 84.7662 186.551 89.9495 186.551H90.0411C95.2346 186.541 102.465 181.896 101.956 175.634L78.0859 179.239Z"
            fill="#A9DE98"
          />
          <path
            d="M103.33 221.465V219.294C103.33 211.962 97.3726 206.003 90.0406 206.003H89.9591C82.6272 206.003 76.6699 211.962 76.6699 219.294V221.465H103.33Z"
            fill="#A9DE98"
          />
          <path
            d="M91.1707 183.784H88.8184V207.124H91.1707V183.784Z"
            fill="#A9DE98"
          />
          <path
            d="M108.33 162.161H71.6701C70.7536 162.161 70 161.414 70 160.495V157.708C70 156.789 70.7536 156.051 71.6701 156.051H108.32C109.246 156.051 109.99 156.799 109.99 157.708V160.495C110 161.414 109.246 162.161 108.33 162.161Z"
            fill="#074674"
          />
          <path
            d="M71.6701 219.849H108.32C109.246 219.849 109.99 220.596 109.99 221.505V224.293C109.99 225.212 109.236 225.949 108.32 225.949H71.6701C70.7434 225.949 70 225.202 70 224.293V221.505C70 220.586 70.7536 219.849 71.6701 219.849Z"
            fill="#074674"
          />
        </g>
        <defs>
          <linearGradient
            id="paint0_linear_4337_9428"
            x1="104.5"
            y1="58"
            x2="195"
            y2="215.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#CCEAFF" />
            <stop offset="1" stopColor="#879DD1" />
          </linearGradient>
          <clipPath id="clip0_4337_9428">
            <rect
              width="75.6908"
              height="33.4448"
              fill="white"
              transform="translate(17.6025 42.2461)"
            />
          </clipPath>
          <clipPath id="clip1_4337_9428">
            <rect
              width="58.0883"
              height="36.0852"
              fill="white"
              transform="translate(218.271 67.7695)"
            />
          </clipPath>
          <clipPath id="clip2_4337_9428">
            <rect
              width="227"
              height="208.451"
              fill="white"
              transform="translate(26 24)"
            />
          </clipPath>
          <clipPath id="clip3_4337_9428">
            <rect
              width="32.2877"
              height="32.2877"
              fill="white"
              transform="translate(107.816 81) rotate(3.22433)"
            />
          </clipPath>
          <clipPath id="clip4_4337_9428">
            <rect
              width="37"
              height="37"
              fill="white"
              transform="translate(137 55)"
            />
          </clipPath>
          <clipPath id="clip5_4337_9428">
            <rect
              width="40"
              height="70"
              fill="white"
              transform="translate(70 156)"
            />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
}
