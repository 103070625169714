export default {
  main: "#1B8158",
  secondary: "#F1F1F1",
  disabled: "#E7E9E7",
  disabledText: "rgba(19, 34, 15, 0.3)",
  textPrimary: "#13220F",
  textSecondary: "#898989",
  textBlack: "#2A2A37",
  terraGreen: "#005C35",
  terraGrey: "#F7F7F7",
  terraWhite: "#FFFFFF",
  error: "#CA4B2B",
  delete: "#C43F3F",
  success: "#60D695",
  grey: "#DEDEDE",
  secondaryGreen: "#E9F3EF",
  disabledInputText: "#ACACAC",
  navGrey: "#AEAEAE",
};
